import React, { useState, useEffect } from "react";
import EmployerSidebar from "../../../shared/Employer-Sidebar";
import HeaderLogged from "../../../shared/HeaderLogged";
import { Link } from "react-router-dom";
import { fetchPricing } from "../../../../states/actions/fetchPricing";
import { useSelector, useDispatch } from "react-redux";
import Text from "../../../common/Text";
import LoadingScreen from "../../../common/LoadingScreen";
import { format_amount } from "../../../../helpers/helpers";

const PricingPlans = () => {
  const dispatch = useDispatch();
  const [pushPopup, setPushPopup] = useState(false);
  const data = useSelector((state) => state.fetchPricing.content);
  const isLoading = useSelector((state) => state.fetchPricing.isLoading);
  const { plans } = data;
  const TogglePush = () => {
    setPushPopup(!pushPopup);
  };

  useEffect(() => {
    dispatch(fetchPricing());
  }, []);

  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <HeaderLogged site_settings={null} />

          <main dashboard="">
            <section className="dash_outer">
              <div className="inner_dash">
                <div className="side_bar">
                  <EmployerSidebar />
                </div>
                <div className="content_area">
                  <div className="dash_header">
                    <h3>
                      {localStorage.getItem("site_lang") === "eng"
                        ? "Dashboard"
                        : "Tableau de bord"}{" "}
                      <span>
                        /
                        {localStorage.getItem("site_lang") === "eng"
                          ? "Pricing Plans"
                          : "Plans tarifaires"}{" "}
                      </span>
                    </h3>
                  </div>
                  <div className="dash_body">
                    <div className="packages dashboard_pkgs">
                      <div className="flex">
                        {plans &&
                          plans.map((row) => (
                            <div className="col">
                              <div
                                className={`inner ${
                                  row.is_active ? "active_pkg" : ""
                                }`}
                              >
                                <div className="top-package">
                                  <div className="topBtn">
                                    <Link
                                      to={
                                        localStorage.getItem("site_lang") ===
                                        "eng"
                                          ? "/pricing"
                                          : "/forfaits"
                                      }
                                      className="webBtn"
                                    >
                                      {localStorage.getItem("site_lang") ===
                                      "eng"
                                        ? row.plan_name
                                        : row.fr_plan_name}
                                    </Link>
                                  </div>
                                  <div className="top-package">
                                    <h4>
                                      {localStorage.getItem("site_lang") ===
                                      "eng"
                                        ? "From"
                                        : "Depuis"}
                                    </h4>
                                    <h3>{format_amount(row.price)}</h3>
                                  </div>
                                  <div className="inner-package">
                                    <Text
                                      string={
                                        localStorage.getItem("site_lang") ===
                                        "eng"
                                          ? row.detail
                                          : row.fr_detail
                                      }
                                    />
                                  </div>

                                  <div className="bTn">
                                    {row.is_active ? (
                                      row.plan_buy_status === "false" ? (
                                        <Link
                                          to={`/employer-signup/${row.encoded_id}`}
                                          className="webBtn"
                                        >
                                          {localStorage.getItem("site_lang") ===
                                          "eng"
                                            ? "Buy Plan"
                                            : "Acheter un forfait"}
                                        </Link>
                                      ) : (
                                        <Link to={""} className="webBtn">
                                          {localStorage.getItem("site_lang") ===
                                          "eng"
                                            ? "Selected"
                                            : "Choisi"}
                                        </Link>
                                      )
                                    ) : (
                                      <Link
                                        to={`/employer-signup/${row.encoded_id}`}
                                        className="webBtn"
                                      >
                                        {localStorage.getItem("site_lang") ===
                                        "eng"
                                          ? "Upgrade Plan"
                                          : "Plan de mise à niveau"}
                                      </Link>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </main>
        </>
      )}
    </>
  );
};

export default PricingPlans;
