import {
    FETCH_EMPLOYER_DETAILS,
    FETCH_EMPLOYER_DETAILS_SUCCESS,
    FETCH_EMPLOYER_DETAILS_FAILED,
} from "../actions/actionTypes";

const initialState = {
    isLoading: true,
    isSearching: false,
    employerDetails: [],
    error: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_EMPLOYER_DETAILS:
            return {
                ...state,
                isLoading: true,
                employerDetails: [],
            };
        case FETCH_EMPLOYER_DETAILS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                employerDetails: action.payload,
            };
        case FETCH_EMPLOYER_DETAILS_FAILED:
            return {
                ...state,
                isLoading: false,
                employerDetails: [],
                error: action.payload,
            };
        default:
            return state;
    }
}
