import React, { useEffect } from "react";
import EmployerSidebar from "../../../shared/Employer-Sidebar";
import HeaderLogged from "../../../shared/HeaderLogged";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { API_UPLOADS_URL } from "../../../../constants/paths";
import LoadingScreen from "../../../common/LoadingScreen";
import { fetchSiteSettings } from "../../../../states/actions/fetchSiteSettings";
import { fetchAllCandidates } from "../../../../states/actions/allCandidates";

const AllCandidates = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.allCandidates.content.candidates);
  const siteSettings = useSelector(
    (state) => state.fetchSiteSettings.siteSettings
  );
  const isSiteSettingsLoading = useSelector(
    (state) => state.fetchSiteSettings.isLoading
  );
  const isLoading = useSelector((state) => state.allCandidates.isLoading);

  useEffect(() => {
    dispatch(fetchAllCandidates());
    dispatch(fetchSiteSettings());
  }, []);

  return (
    <>
      {isLoading || isSiteSettingsLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <HeaderLogged site_settings={siteSettings} />
          <main dashboard="">
            <section className="dash_outer">
              <div className="inner_dash">
                <div className="side_bar">
                  <EmployerSidebar />
                </div>
                <div className="content_area">
                  <div className="dash_header">
                    <h3>
                      {localStorage.getItem("site_lang") === "eng"
                        ? "Dashboard"
                        : "Tableau de bord"}{" "}
                      <span>
                        /
                        {localStorage.getItem("site_lang") === "eng"
                          ? "Candidates"
                          : "Candidats"}
                      </span>
                    </h3>
                  </div>
                  <div className="dash_body">
                    <div className="flex job_flex candidate_flex_lst dash_board_candidates">
                      {data?.length > 0 ? (
                        data?.map((item, index) => (
                          <div className="col" key={index}>
                            <div className="inner">
                              {/* <Link to="/" className="chat_abt_blk">
                                <img src="/images/dashboard/chat.svg" alt="" />
                              </Link> */}
                              <div className="head_job">
                                <div className="img_ico">
                                  {item?.mem_image?.trim() === "" ? (
                                    <img src="/images/user.png" alt="" />
                                  ) : (
                                    <img
                                      src={`${API_UPLOADS_URL}/members/${item?.mem_image}`}
                                      alt=""
                                    />
                                  )}
                                </div>
                                <div className="cntnt">
                                  <h4>
                                    <Link
                                      to={
                                        "/candidate/candidate-detail/" +
                                        item?.mem_id
                                      }
                                      target="_blank"
                                    >
                                      {item?.mem_fname + " " + item?.mem_lname}
                                    </Link>
                                  </h4>
                                  <p>
                                    <small>{item?.profession}</small>
                                  </p>
                                </div>
                              </div>
                              <div className="job_bdy">
                                <ul>
                                  <li>
                                    <i className="fi fi-rr-marker"></i>{" "}
                                    <span>
                                      {item?.mem_country}, {item?.mem_city}
                                    </span>
                                  </li>
                                  <li>
                                    <i className="fi fi-rr-briefcase"></i>{" "}
                                    <span>
                                      {item?.mem_experience}{" "}
                                      {localStorage.getItem("site_lang") ===
                                      "eng"
                                        ? "Years Experience"
                                        : "Années d'expérience"}
                                    </span>
                                  </li>
                                </ul>

                                <div className="skils">
                                  {item?.details?.skills
                                    ?.split(",")
                                    .map((tag, index) => (
                                      <span key={index}>{tag}</span>
                                    ))}
                                </div>
                                {item?.job_names?.length > 0 ? (
                                  <>
                                    <br />
                                    <h5>
                                      {localStorage.getItem("site_lang") ===
                                      "eng"
                                        ? "Applied Jobs"
                                        : "Emplois appliqués"}
                                    </h5>
                                    <div className="skils">
                                      {item?.job_names?.map((job, index) => {
                                        return (
                                          <span key={index}>
                                            <Link
                                              to={"/job-details/" + job?.job_id}
                                            >
                                              {localStorage.getItem(
                                                "site_lang"
                                              ) === "eng"
                                                ? job?.title
                                                : job?.fr_title}
                                            </Link>
                                          </span>
                                        );
                                      })}
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                              {/* <div className="act_dash_lnk_new">
                                <h5>
                                  <strong>Cover Letter</strong>
                                </h5>
                                {ReactHtmlParser(item?.cover_letter)}
                              </div> */}
                              <div className="download_resume">
                                <a
                                  className="webBtn icoBtn"
                                  href={
                                    API_UPLOADS_URL +
                                    "/members/resume/" +
                                    item?.details?.resume
                                  }
                                >
                                  {localStorage.getItem("site_lang") === "eng"
                                    ? "Download Resume"
                                    : "Télécharger le CV"}
                                </a>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="col">
                          <div className="inner">
                            <div className="no_data">
                              <h4>
                                {localStorage.getItem("site_lang") === "eng"
                                  ? "No Candidates Found"
                                  : "Aucun candidat trouvé"}
                              </h4>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </main>
        </>
      )}
    </>
  );
};

export default AllCandidates;
