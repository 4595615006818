module.exports = {
  TOAST_SETTINGS: {
    position: "top-right",
    autoClose: 7000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    newestOnTop: true
  }
};
