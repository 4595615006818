import React, { useEffect, useState } from "react";
import { API_UPLOADS_URL } from "../../../../constants/paths";
import { useForm } from "react-hook-form";
import UploadImage from "../../../common/upload-image";
import http from "../../../../helpers/http";
import { doObjToFormData } from "../../../../helpers/helpers";
import { toast, ToastContainer } from "react-toastify";
import { TOAST_SETTINGS } from "../../../../utils/siteSettings";
import FormProcessingSpinner from "../../../common/FormProcessingSpinner";

export default function EmployerProfileForm({ employerData }) {
  const {
    register,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const selectedLanguage = localStorage.getItem("site_lang");
  const [attachment, setAttachmentsName] = useState({
    isLoading: false,
    image: null,
  });
  useEffect(() => {
    setAttachmentsName({ ...attachment, image: employerData?.mem_image });
  }, [employerData]);
  const [isFormProcessing, setIsFormProcessing] = useState(false);

  const onSubmit = (frmData) => {
    frmData = {
      ...frmData,
      image: attachment?.image,
      authToken: localStorage.getItem("authToken"),
    };
    setIsFormProcessing(true);
    http
      .post("/user/save-employer-profile-settings", doObjToFormData(frmData))
      .then((res) => {
        setIsFormProcessing(false);
        if (res?.data?.status) {
          toast.success(
            selectedLanguage === "eng"
              ? "Profile have been created successfully."
              : "Le profil a été créé avec succès.",
            TOAST_SETTINGS
          );
        } else {
          toast.error(
            selectedLanguage === "eng"
              ? "Technical Problem!"
              : "Problème technique !",
            TOAST_SETTINGS
          );
        }
      })
      .catch((err) => {
        setIsFormProcessing(false);
        console.log(err);
      });
  };
  return (
    <>
      <ToastContainer />
      <form action method="post" onSubmit={handleSubmit(onSubmit)}>
        <div className="_header">
          <h4>
            {selectedLanguage === "eng"
              ? "Account Details"
              : "Détails du compte"}
          </h4>
        </div>
        <UploadImage
          setAttachmentsName={setAttachmentsName}
          attachment={attachment}
        />
        <hr />
        <h5>
          {selectedLanguage === "eng"
            ? "Personal Information"
            : "Informations personnelles"}
        </h5>
        <div className="row formRow">
          <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-xx-4">
            <div className="txtGrp">
              <label className="move">
                {selectedLanguage === "eng" ? "Company Name" : "Enterprise"}
              </label>
              <input
                type="text"
                className="txtBox"
                {...register("company_name", {
                  required:
                    selectedLanguage === "eng"
                      ? "Company name is required."
                      : "Le nom de la compagnie est requis.",
                })}
                defaultValue={employerData?.mem_company}
              />
              <span className="validation-error">
                {errors.company_name?.message}
              </span>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-xx-4">
            <div className="txtGrp">
              <label className="move">
                {selectedLanguage === "eng" ? "First Name" : "Le prénom"}
              </label>
              <input
                type="text"
                className="txtBox"
                {...register("fname", {
                  required:
                    selectedLanguage === "eng"
                      ? "First Name is required."
                      : "Le prénom est requis.",
                  maxLength: {
                    value: 20,
                    message:
                      selectedLanguage === "eng"
                        ? "First Name should Not be greater than 20 characters."
                        : "Le prénom ne doit pas dépasser 20 caractères.",
                  },
                  minLength: {
                    value: 2,
                    message:
                      selectedLanguage === "eng"
                        ? "First Name should be greater than atleast 2 characters."
                        : "Le prénom doit comporter au moins 2 caractères.",
                  },
                })}
                defaultValue={employerData?.mem_fname}
              />
              <span className="validation-error">{errors.fname?.message}</span>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-xx-4">
            <div className="txtGrp">
              <label className="move">
                {selectedLanguage === "eng" ? "Last Name" : "Le nom"}
              </label>
              <input
                type="text"
                className="txtBox"
                {...register("lname", {
                  required:
                    selectedLanguage === "eng"
                      ? "Last Name is required."
                      : "Le nom de famille est requis.",
                  maxLength: {
                    value: 20,
                    message:
                      selectedLanguage === "eng"
                        ? "Last Name should Not be greater than 20 characters."
                        : "Le nom de famille ne doit pas dépasser 20 caractères.",
                  },
                  minLength: {
                    value: 2,
                    message:
                      selectedLanguage === "eng"
                        ? "Last Name should be greater than atleast 2 characters."
                        : "Le nom de famille doit comporter au moins 2 caractères.",
                  },
                })}
                defaultValue={employerData?.mem_lname}
              />
              <span className="validation-error">{errors.lname?.message}</span>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-xx-4">
            <div className="txtGrp">
              <label className="move">
                {selectedLanguage === "eng" ? "Phone Number" : "Le numéro"}
              </label>
              <input
                type="text"
                name="phone"
                id="phone"
                className="txtBox"
                {...register("phone", {
                  required:
                    selectedLanguage === "eng"
                      ? "Phone Number is required."
                      : "Le numéro de téléphone est requis.",
                })}
                defaultValue={employerData?.mem_phone}
              />
              <span className="validation-error">{errors.phone?.message}</span>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-xx-4">
            <div className="txtGrp">
              <label className="move">
                {selectedLanguage === "eng" ? "Email Address" : "Adresse email"}
              </label>
              <input
                type="text"
                id
                name
                className="txtBox"
                readOnly
                defaultValue={employerData?.mem_email}
              />
            </div>
          </div>
        </div>
        <hr />
        <h5>
          {selectedLanguage === "eng"
            ? "Address Information"
            : "Informations sur l'adresse"}
        </h5>
        <div className="row formRow">
          <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-xx-4">
            <div className="txtGrp">
              <label className="move">
                {selectedLanguage === "eng" ? "Address" : "L'adresse"}
              </label>
              <input
                type="text"
                className="txtBox"
                {...register("address", {
                  required:
                    selectedLanguage === "eng"
                      ? "Address is required."
                      : "L'adresse est requise.",
                })}
                defaultValue={employerData?.mem_address}
              />
              <span className="validation-error">
                {errors.address?.message}
              </span>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-xx-4">
            <div className="txtGrp">
              <label className="move">
                {selectedLanguage === "eng" ? "Province" : "La province"}
              </label>
              <select
                className="txtBox"
                {...register("province", {
                  required:
                    selectedLanguage === "eng"
                      ? "Province is required."
                      : "La province est requise.",
                })}
              >
                <option value="" />
                <option
                  value="AB"
                  selected={employerData?.mem_province === "AB" ? true : false}
                >
                  Alberta
                </option>
                <option
                  value="BC"
                  selected={employerData?.mem_province === "BC" ? true : false}
                >
                  British Columbia
                </option>
                <option
                  value="MB"
                  selected={employerData?.mem_province === "MB" ? true : false}
                >
                  Manitoba
                </option>
                <option
                  value="NB"
                  selected={employerData?.mem_province === "NB" ? true : false}
                >
                  New Brunswick
                </option>
                <option
                  value="NL"
                  selected={employerData?.mem_province === "NL" ? true : false}
                >
                  Newfoundland and Labrador
                </option>
                <option
                  value="NT"
                  selected={employerData?.mem_province === "NT" ? true : false}
                >
                  Northwest Territories
                </option>
                <option
                  value="NS"
                  selected={employerData?.mem_province === "NS" ? true : false}
                >
                  Nova Scotia
                </option>
                <option
                  value="NU"
                  selected={employerData?.mem_province === "NU" ? true : false}
                >
                  Nunavut
                </option>
                <option
                  value="ON"
                  selected={employerData?.mem_province === "ON" ? true : false}
                >
                  Ontario
                </option>
                <option
                  value="PE"
                  selected={employerData?.mem_province === "PE" ? true : false}
                >
                  Prince Edward Island
                </option>
                <option
                  value="QC"
                  selected={employerData?.mem_province === "QC" ? true : false}
                >
                  Quebec
                </option>
                <option
                  value="SK"
                  selected={employerData?.mem_province === "SK" ? true : false}
                >
                  Saskatchewan
                </option>
                <option
                  value="YT"
                  selected={employerData?.mem_province === "YT" ? true : false}
                >
                  Yukon
                </option>
                <option
                  value="--"
                  selected={employerData?.mem_province === "--" ? true : false}
                >
                  --Other--
                </option>
                <option
                  value="AF"
                  selected={employerData?.mem_province === "AF" ? true : false}
                >
                  Africa
                </option>
                <option
                  value="NA"
                  selected={employerData?.mem_province === "NA" ? true : false}
                >
                  United States / Mexico
                </option>
                <option
                  value="SA"
                  selected={employerData?.mem_province === "SA" ? true : false}
                >
                  South America
                </option>
                <option
                  value="EU"
                  selected={employerData?.mem_province === "EU" ? true : false}
                >
                  Europe
                </option>
                <option
                  value="AS"
                  selected={employerData?.mem_province === "AS" ? true : false}
                >
                  Asia
                </option>
                <option
                  value="OC"
                  selected={employerData?.mem_province === "OC" ? true : false}
                >
                  Oceania
                </option>
              </select>
              <span className="validation-error">
                {errors.province?.message}
              </span>
            </div>
          </div>
        </div>
        <hr />
        <h5>
          {selectedLanguage === "eng" ? "Profile Bio" : "Biographie du profil"}
        </h5>
        <div className="row formRow">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xx-12">
            <div className="txtGrp">
              <label className="move">Description</label>
              <textarea className="txtBox" {...register("company_description")}>
                {employerData?.mem_company_description}
              </textarea>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-xx-12">
            <div className="txtGrp">
              <label className="move">
                {selectedLanguage === "eng" ? "Website" : "Site web"}
              </label>
              <input
                type="text"
                className="txtBox"
                {...register("website")}
                defaultValue={employerData?.mem_website}
              />
            </div>
          </div>
        </div>
        <hr />
        <h5>
          {selectedLanguage === "eng"
            ? "Social Media Links"
            : "Liens de médias sociaux"}
        </h5>
        <div className="row formRow">
          <div className="col-md-6">
            <div className="txtGrp">
              <label className="move">
                {" "}
                {selectedLanguage === "eng"
                  ? "Facebook URL address"
                  : "L'adresse URL de Facebook"}
              </label>
              <input
                type="text"
                className="txtBox"
                {...register("facebook_link")}
                defaultValue={employerData?.mem_facebook_link}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="txtGrp">
              <label className="move">
                {" "}
                {selectedLanguage === "eng" ? "Twitter URL" : "URL Twitter"}
              </label>
              <input
                type="text"
                className="txtBox"
                {...register("twitter_link")}
                defaultValue={employerData?.mem_twitter_link}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="txtGrp">
              <label className="move">
                {" "}
                {selectedLanguage === "eng" ? "Instagram URL" : "URL Instagram"}
              </label>
              <input
                type="text"
                className="txtBox"
                {...register("instagram_link")}
                defaultValue={employerData?.mem_instagram_link}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="txtGrp">
              <label className="move">
                {" "}
                {selectedLanguage === "eng" ? "Linkedin URL" : "URL Linkedin"}
              </label>
              <input
                type="text"
                className="txtBox"
                {...register("linkedin_link")}
                defaultValue={employerData?.mem_linkedin_link}
              />
            </div>
          </div>
        </div>
        <div className="bTn formBtn text-center">
          <button
            type="submit"
            className="webBtn"
            disabled={isFormProcessing || attachment?.isLoading}
          >
            {selectedLanguage === "eng" ? "Save" : "Sauvegarder"}
            {isFormProcessing || attachment?.isLoading ? (
              <FormProcessingSpinner
                isFormProcessing={isFormProcessing || attachment?.isLoading}
              />
            ) : (
              ""
            )}
          </button>
        </div>
      </form>
    </>
  );
}
