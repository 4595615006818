import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchEmployerDetails } from "../../../states/actions/fetchEmployerDetails";
import LoadingScreen from "../../common/LoadingScreen";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import Header from "../../shared/Header";
import { ToastContainer } from "react-toastify";
import { API_UPLOADS_URL } from "../../../constants/paths";
import ReactHtmlParser from "html-react-parser";

export default function EmployerDetails() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const isLoading = useSelector(
    (state) => state.fetchEmployerDetails.isLoading
  );
  const employer_row = useSelector(
    (state) => state.fetchEmployerDetails.employerDetails.employer
  );
  const site_settings = useSelector(
    (state) => state.fetchEmployerDetails.employerDetails.site_settings
  );
  useEffect(() => {
    dispatch(fetchEmployerDetails(id));
  }, [id]);
  console.log(employer_row);
  useDocumentTitle(
    employer_row?.mem_company + " - " + site_settings?.site_name
  );
  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <ToastContainer />
          <Header site_settings={site_settings} />
          <main index="">
            <section className="job_detail_banner">
              <div className="contain">
                <div className="cntnt">
                  <div className="job_flex_title">
                    <div className="img_ico">
                      <img
                        src={
                          API_UPLOADS_URL +
                          "/members/" +
                          employer_row?.mem_image
                        }
                        alt=""
                      />
                    </div>
                    <div className="right_lst">
                      <h4>{employer_row?.mem_company}</h4>
                    </div>
                  </div>
                  <h1>
                    {employer_row?.mem_fname + " " + employer_row?.mem_lname}
                  </h1>
                  <p>
                    <i className="fi fi-rr-marker"></i>{" "}
                    <span>
                      {employer_row?.mem_address +
                        ", " +
                        employer_row?.mem_province}
                    </span>
                  </p>
                </div>
              </div>
            </section>
            <section className="job_detail_sec">
              <div className="contain">
                <div className="cntnt">
                  <div className="job_detail_grid_pg">
                    <ul>
                      <li>
                        <h4>
                          <i className="fi fi-rr-briefcase"></i>
                          <span>
                            {localStorage.getItem("site_lang") === "eng"
                              ? "Website"
                              : "Site web"}
                          </span>
                        </h4>
                        <p>{employer_row?.mem_website}</p>
                      </li>
                      <li>
                        <h4>
                          <i className="fi fi-rr-briefcase"></i>
                          <span>
                            {localStorage.getItem("site_lang") === "eng"
                              ? "Phone"
                              : "Téléphone"}
                          </span>
                        </h4>
                        <p>{employer_row?.mem_phone}</p>
                      </li>
                    </ul>
                    <br />
                    <div className="ckEditor">
                      <h4>
                        {localStorage.getItem("site_lang") === "eng"
                          ? "Description"
                          : "Description"}
                      </h4>
                      {employer_row?.mem_company_description &&
                        ReactHtmlParser(employer_row?.mem_company_description)}
                    </div>
                    {employer_row?.mem_facebook_link ||
                    employer_row?.mem_twitter_link ||
                    employer_row?.mem_instagram_link ||
                    employer_row?.mem_linkedin_link ? (
                      <div className="share_blk flex">
                        <div className="flex">
                          <span>
                            {localStorage.getItem("site_lang") === "eng"
                              ? "Social Links: "
                              : "Liens sociaux :"}
                          </span>
                          <div className="social flex">
                            {employer_row?.mem_facebook_link ? (
                              <li>
                                <a href={employer_row?.mem_facebook_link}>
                                  <img
                                    src="/images/social-facebook.svg"
                                    alt=""
                                  />
                                </a>
                              </li>
                            ) : (
                              ""
                            )}
                            {employer_row?.mem_twitter_link ? (
                              <li>
                                <a href={employer_row?.mem_twitter_link}>
                                  <img
                                    src="/images/social-twitter.svg"
                                    alt=""
                                  />
                                </a>
                              </li>
                            ) : (
                              ""
                            )}
                            {employer_row?.mem_instagram_link ? (
                              <li>
                                <a href={employer_row?.mem_instagram_link}>
                                  <img
                                    src="/images/social-instagram.svg"
                                    alt=""
                                  />
                                </a>
                              </li>
                            ) : (
                              ""
                            )}
                            {employer_row?.mem_linkedin_link ? (
                              <li>
                                <a href={employer_row?.mem_linkedin_link}>
                                  <img
                                    src="/images/social-linkedin.svg"
                                    alt=""
                                  />
                                </a>
                              </li>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </section>
          </main>
        </>
      )}
    </>
  );
}
