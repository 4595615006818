import React, { useEffect, useState } from "react";
import EmployerSidebar from "../../../shared/Employer-Sidebar";
import HeaderLogged from "../../../shared/HeaderLogged";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchSiteSettings } from "../../../../states/actions/fetchSiteSettings";
import { fetchEmployerData } from "../../../../states/actions/employerData";
import LoadingScreen from "../../../common/LoadingScreen";
import EmployerProfileForm from "./profile-form";
import ChangePassword from "./changePassword";

const ProfileSetting = () => {
  const dispatch = useDispatch();
  const employer = useSelector(
    (state) => state?.employerData?.content?.employer
  );
  const siteSettings = useSelector(
    (state) => state.fetchSiteSettings.siteSettings
  );

  const isSiteSettingsLoading = useSelector(
    (state) => state.fetchSiteSettings.isLoading
  );
  const isLoading = useSelector((state) => state.employerData.isLoading);
  useEffect(() => {
    dispatch(fetchEmployerData());
    dispatch(fetchSiteSettings());
  }, []);
  return (
    <>
      {isLoading || isSiteSettingsLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <HeaderLogged />
          <main dashboard="">
            <section className="dash_outer">
              <div className="inner_dash">
                <div className="side_bar">
                  <EmployerSidebar />
                </div>
                <div className="content_area">
                  <div className="dash_header">
                    {localStorage.getItem("site_lang") === "eng" ? (
                      <h3>
                        Dashboard <span>/ Profile Settings</span>
                      </h3>
                    ) : (
                      <h3>
                        Tableau de bord <span>/ Paramètres du profil</span>
                      </h3>
                    )}
                  </div>
                  <div className="dash_body" id="setting">
                    <div className="dash_heading_sec">
                      {localStorage.getItem("site_lang") === "eng" ? (
                        <h2>Profile Settings</h2>
                      ) : (
                        <h2>Paramètres du profil</h2>
                      )}
                    </div>
                    <div className="dash_blk_box">
                      <EmployerProfileForm employerData={employer} />
                    </div>
                    <ChangePassword />
                  </div>
                </div>
              </div>
            </section>
          </main>
        </>
      )}
    </>
  );
};

export default ProfileSetting;
