import React, { useRef } from "react";
import http from "../../helpers/http";
import { API_UPLOADS_URL } from "../../constants/paths";

export default function UploadImage({ setAttachmentsName, attachment }) {
  const attachmentRef = useRef(null);
  const handleFiles = (e) => {
    e.preventDefault();
    attachmentRef.current.click();
  };
  const handleSelectAttachment = (e) => {
    setAttachmentsName({ ...attachment, isLoading: true });

    let formData = new FormData();
    formData.append("image", e.target.files[0]);
    formData.append("authToken", localStorage.getItem("authToken"));

    http
      .post("/upload-image", formData)
      .then((res) => {
        document.getElementById("attachment").value = "";
        if (res?.data?.status) {
          setAttachmentsName({
            ...attachment,
            image: res.data.image,
            isLoading: false,
          });
        } else {
          setAttachmentsName({ ...attachment, isLoading: false });
        }
      })
      .catch((err) => {
        console.log(err);
        setAttachmentsName({ ...attachment, isLoading: false });
        document.getElementById("attachment").value = "";
      });
  };
  return (
    <>
      <div className="upLoadDp">
        <div className="ico">
          <img
            src={
              attachment?.isLoading === true
                ? "/images/loading.gif"
                : attachment?.image !== undefined &&
                  attachment?.image !== null &&
                  attachment?.image !== ""
                ? API_UPLOADS_URL + "/members/" + attachment?.image
                : "/images/no-user.svg"
            }
            alt=""
          />
        </div>
        <div className="text-center">
          <button
            type="button"
            className="webBtn smBtn uploadImg"
            data-upload="dp_image"
            data-text="Change Photo"
            onClick={handleFiles}
          >
            Change Photo
          </button>
          <input
            type="file"
            name
            id="attachment"
            className="uploadFile"
            data-upload="dp_image"
            ref={attachmentRef}
            onChange={handleSelectAttachment}
          />
        </div>
        <div className="noHats text-center">(Please upload your photo)</div>
      </div>
    </>
  );
}
