import React, { useState } from "react";
import { useForm } from "react-hook-form";
import http from "../../../../helpers/http";
import { doObjToFormData } from "../../../../helpers/helpers";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../../../utils/siteSettings";
import FormProcessingSpinner from "../../../common/FormProcessingSpinner";

export default function ChangePassword() {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const [isFormProcessing, setIsFormProcessing] = useState(false);
  const [showPassword, setShowPassword] = useState({
    current: false,
    new: false,
    confirm: false,
  });

  const togglePasswordVisibility = (field) => {
    setShowPassword((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };
  const selectedLanguage = localStorage.getItem("site_lang");
  const onSubmit = (frmData) => {
    frmData = {
      ...frmData,
      authToken: localStorage.getItem("authToken"),
    };
    setIsFormProcessing(true);
    http
      .post("/user/change-password", doObjToFormData(frmData))
      .then((res) => {
        setIsFormProcessing(false);
        if (res?.data?.status) {
          reset();
          toast.success(
            selectedLanguage === "eng"
              ? "Password changed successfully."
              : "Le mot de passe a été modifié avec succès.",
            TOAST_SETTINGS
          );
        } else {
          toast.error(
            selectedLanguage === "eng"
              ? "Technical Problem!"
              : "Problème technique !",
            TOAST_SETTINGS
          );
        }
      })
      .catch((err) => {
        setIsFormProcessing(false);
        console.log(err);
      });
  };

  return (
    <>
      <div className="dash_blk_box">
        <div className="_header">
          <h4>
            {selectedLanguage === "french"
              ? "Changer le mot de passe"
              : "Change Password"}
          </h4>
          <div className="info">
            <strong>
              <em>
                {selectedLanguage === "french"
                  ? "Mot de passe fort"
                  : "Strong Password"}
              </em>
            </strong>
            <div className="infoIn ckEditor">
              <p>
                {selectedLanguage === "french"
                  ? "Votre mot de passe doit contenir les éléments suivants :"
                  : "Your password must contain the following:"}
              </p>
              <ol>
                <li>
                  {selectedLanguage === "french"
                    ? "Au moins 8 caractères de long (un mot de passe fort a au moins 14 caractères)"
                    : "At least 8 characters in length (a strong password has at least 14 characters)"}
                </li>
                <li>
                  {selectedLanguage === "french"
                    ? "Au moins 1 lettre et au moins 1 chiffre ou symbole"
                    : "At least 1 letter and at least 1 number or symbol"}
                </li>
              </ol>
            </div>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} method="post">
          <div className="row formRow">
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-xx-4">
              <div className="txtGrp pasDv">
                <label htmlFor="currentPassword" className="move">
                  {selectedLanguage === "french"
                    ? "Mot de passe actuel"
                    : "Current password"}
                </label>

                <input
                  type={showPassword.current ? "text" : "password"}
                  id="currentPassword"
                  className="txtBox"
                  {...register("pass", {
                    required:
                      selectedLanguage === "french"
                        ? "Le mot de passe actuel est requis."
                        : "Current password is required.",
                  })}
                />
                {errors.pass && (
                  <p className="errorMsg">{errors.pass.message}</p>
                )}
                <i
                  className="icon-eye"
                  id="eye"
                  onClick={() => togglePasswordVisibility("current")}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </div>

            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-xx-4">
              <div className="txtGrp pasDv">
                <label htmlFor="new_pass" className="move">
                  New password
                </label>
                <input
                  type={showPassword.new ? "text" : "password"}
                  id="new_pass"
                  className="txtBox"
                  {...register("new_pass", {
                    required:
                      selectedLanguage === "french"
                        ? "Un nouveau mot de passe est requis."
                        : "New password is required.",
                    minLength: {
                      value: 6,
                      message:
                        selectedLanguage === "french"
                          ? "Le mot de passe doit comporter au moins 6 caractères."
                          : "Password should be at least 6 characters long.",
                    },
                  })}
                />
                {errors.new_pass && (
                  <p className="errorMsg">{errors.new_pass.message}</p>
                )}
                <i
                  className="icon-eye"
                  id="eye"
                  onClick={() => togglePasswordVisibility("new")}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </div>

            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-xx-4">
              <div className="txtGrp pasDv">
                <label htmlFor="confirm_pass" className="move">
                  {selectedLanguage === "french"
                    ? "Confirmer le nouveau mot de passe"
                    : "Confirm new password"}
                </label>

                <input
                  type={showPassword.confirm ? "text" : "password"}
                  id="confirm_pass"
                  className="txtBox"
                  {...register("confirm_pass", {
                    required:
                      selectedLanguage === "french"
                        ? "Confirmer que le mot de passe est requis."
                        : "Confirm password is required.",
                    validate: (value) =>
                      value === watch("new_pass") ||
                      selectedLanguage === "french"
                        ? "Les mots de passe ne correspondent pas."
                        : "Passwords do not match.",
                  })}
                />
                {errors.confirm_pass && (
                  <p className="errorMsg">{errors.confirm_pass.message}</p>
                )}
                <i
                  className="icon-eye"
                  id="eye"
                  onClick={() => togglePasswordVisibility("confirm")}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </div>
          </div>

          <div className="bTn formBtn text-center">
            <button
              type="submit"
              className="webBtn"
              disabled={isFormProcessing}
            >
              {selectedLanguage === "french" ? "Changement" : "Change"}
              {isFormProcessing ? (
                <FormProcessingSpinner isFormProcessing={isFormProcessing} />
              ) : (
                ""
              )}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
