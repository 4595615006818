export const FETCH_SITE_SETTINGS = "FETCH_SITE_SETTINGS";
export const FETCH_SITE_SETTINGS_SUCCESS = "FETCH_SITE_SETTINGS_SUCCESS";
export const FETCH_SITE_SETTINGS_FAILED = "FETCH_SITE_SETTINGS_FAILED";

export const FETCH_HOME_CONTENT = "FETCH_HOME_CONTENT";
export const FETCH_HOME_CONTENT_SUCCESS = "FETCH_HOME_CONTENT_SUCCESS";
export const FETCH_HOME_CONTENT_FAILED = "FETCH_HOME_CONTENT_FAILED";

export const FETCH_EMPLOYER_DATA = "FETCH_EMPLOYER_DATA";
export const FETCH_EMPLOYER_DATA_SUCCESS = "FETCH_EMPLOYER_DATA_SUCCESS";
export const FETCH_EMPLOYER_DATA_FAILED = "FETCH_EMPLOYER_DATA_FAILED";

export const FETCH_EMPLOYER_HOME_CONTENT = "FETCH_EMPLOYER_HOME_CONTENT";
export const FETCH_EMPLOYER_HOME_CONTENT_SUCCESS =
  "FETCH_EMPLOYER_HOME_CONTENT_SUCCESS";
export const FETCH_EMPLOYER_HOME_CONTENT_FAILED =
  "FETCH_EMPLOYER_HOME_CONTENT_FAILED";

export const FETCH_TERMS_AND_CONDITIONS_CONTENT =
  "FETCH_TERMS_AND_CONDITIONS_CONTENT";
export const FETCH_TERMS_AND_CONDITIONS_CONTENT_SUCCESS =
  "FETCH_TERMS_AND_CONDITIONS_CONTENT_SUCCESS";
export const FETCH_TERMS_AND_CONDITIONS_CONTENT_FAILED =
  "FETCH_TERMS_AND_CONDITIONS_CONTENT_FAILED";

export const FETCH_PRIVACY_POLICY_CONTENT = "FETCH_PRIVACY_POLICY_CONTENT";
export const FETCH_PRIVACY_POLICY_CONTENT_SUCCESS =
  "FETCH_PRIVACY_POLICY_CONTENT_SUCCESS";
export const FETCH_PRIVACY_POLICY_CONTENT_FAILED =
  "FETCH_PRIVACY_POLICY_CONTENT_FAILED";

export const FETCH_FAQ_CONTENT = "FETCH_FAQ_CONTENT";
export const FETCH_FAQ_CONTENT_SUCCESS = "FETCH_FAQ_CONTENT_SUCCESS";
export const FETCH_FAQ_CONTENT_FAILED = "FETCH_FAQ_CONTENT_FAILED";

export const FETCH_DISCLAIMER_CONTENT = "FETCH_DISCLAIMER_CONTENT";
export const FETCH_DISCLAIMER_CONTENT_SUCCESS =
  "FETCH_DISCLAIMER_CONTENT_SUCCESS";
export const FETCH_DISCLAIMER_CONTENT_FAILED =
  "FETCH_DISCLAIMER_CONTENT_FAILED";

export const FETCH_WORK_WITH_US_CONTENT = "FETCH_WORK_WITH_US_CONTENT";
export const FETCH_WORK_WITH_US_CONTENT_SUCCESS =
  "FETCH_WORK_WITH_US_CONTENT_SUCCESS";
export const FETCH_WORK_WITH_US_CONTENT_FAILED =
  "FETCH_WORK_WITH_US_CONTENT_FAILED";

export const FETCH_PARTNER_WITH_US_CONTENT = "FETCH_PARTNER_WITH_US_CONTENT";
export const FETCH_PARTNER_WITH_US_CONTENT_SUCCESS =
  "FETCH_PARTNER_WITH_US_CONTENT_SUCCESS";
export const FETCH_PARTNER_WITH_US_CONTENT_FAILED =
  "FETCH_PARTNER_WITH_US_CONTENT_FAILED";

export const FETCH_CAREERS_CONTENT = "FETCH_CAREERS_CONTENT";
export const FETCH_CAREERS_CONTENT_SUCCESS = "FETCH_CAREERS_CONTENT_SUCCESS";
export const FETCH_CAREERS_CONTENT_FAILED = "FETCH_CAREERS_CONTENT_FAILED";

export const FETCH_HOW_IT_WORKS = "FETCH_HOW_IT_WORKS";
export const FETCH_HOW_IT_WORKS_SUCCESS = "FETCH_HOW_IT_WORKS_SUCCESS";
export const FETCH_HOW_IT_WORKS_FAILED = "FETCH_HOW_IT_WORKS_FAILED";

export const FETCH_FETCH_JOB_CONTENT = "FETCH_FETCH_JOB_CONTENT";
export const FETCH_FETCH_JOB_CONTENT_SUCCESS =
  "FETCH_FETCH_JOB_CONTENT_SUCCESS";
export const FETCH_FETCH_JOB_CONTENT_FAILED = "FETCH_FETCH_JOB_CONTENT_FAILED";

export const FETCH_JOBS_CONTENT = "FETCH_JOBS_CONTENT";
export const FETCH_JOBS_CONTENT_SUCCESS = "FETCH_JOBS_CONTENT_SUCCESS";
export const FETCH_JOBS_CONTENT_FAILED = "FETCH_JOBS_CONTENT_FAILED";
export const FETCH_JOBS_SEARCH = "FETCH_JOBS_SEARCH";
export const FETCH_JOBS_SEARCH_SUCCESS = "FETCH_JOBS_SEARCH_SUCCESS";
export const FETCH_JOBS_SEARCH_FAILED = "FETCH_JOBS_SEARCH_FAILED";
export const SAVE_JOB = "SAVE_JOB";
export const SAVE_JOB_SUCCESS = "SAVE_JOB_SUCCESS";
export const SAVE_JOB_FAILED = "SAVE_JOB_FAILED";
export const FETCH_JOBS_CATEGORY = "FETCH_JOBS_CATEGORY";
export const FETCH_JOBS_CATEGORY_SUCCESS = "FETCH_JOBS_CATEGORY_SUCCESS";
export const FETCH_JOBS_CATEGORY_FAILED = "FETCH_JOBS_CATEGORY_FAILED";
export const FETCH_JOBS_SUB_CATEGORY = "FETCH_JOBS_SUB_CATEGORY";
export const FETCH_JOBS_SUB_CATEGORY_SUCCESS =
  "FETCH_JOBS_SUB_CATEGORY_SUCCESS";
export const FETCH_JOBS_SUB_CATEGORY_FAILED = "FETCH_JOBS_SUB_CATEGORY_FAILED";
export const FETCH_JOB_TYPES = "FETCH_JOB_TYPES";
export const FETCH_JOB_TYPES_SUCCESS = "FETCH_JOB_TYPES_SUCCESS";
export const FETCH_JOB_TYPES_FAILED = "FETCH_JOB_TYPES_FAILED";
export const FETCH_FILTERED_JOBS = "FETCH_FILTERED_JOBS";
export const FETCH_FILTERED_JOBS_SUCCESS = "FETCH_FILTERED_JOBS_SUCCESS";
export const FETCH_FILTERED_JOBS_FAILED = "FETCH_FILTERED_JOBS_FAILED";

export const FETCH_JOB_EXPERIENCE_LEVELS = "FETCH_JOB_EXPERIENCE_LEVELS";
export const FETCH_JOB_EXPERIENCE_LEVELS_SUCCESS =
  "FETCH_JOB_EXPERIENCE_LEVELS_SUCCESS";
export const FETCH_JOB_EXPERIENCE_LEVELS_FAILED =
  "FETCH_JOB_EXPERIENCE_LEVELS_FAILED";

export const SAVE_EMAIL_FOR_NEWSLETTER = "SAVE_EMAIL_FOR_NEWSLETTER";
export const SAVE_EMAIL_FOR_NEWSLETTER_SUCCESS =
  "SAVE_EMAIL_FOR_NEWSLETTER_SUCCESS";
export const SAVE_EMAIL_FOR_NEWSLETTER_FAILED =
  "SAVE_EMAIL_FOR_NEWSLETTER_FAILED";

export const FETCH_JOB_LOCATIONS = "FETCH_JOB_LOCATIONS";
export const FETCH_JOB_LOCATIONS_SUCCESS = "FETCH_JOB_LOCATIONS_SUCCESS";
export const FETCH_JOB_LOCATIONS_FAILED = "FETCH_JOB_LOCATIONS_FAILED";

export const FETCH_ABOUT_US_CONTENT = "FETCH_ABOUT_US_CONTENT";
export const FETCH_ABOUT_US_CONTENT_SUCCESS = "FETCH_ABOUT_US_CONTENT_SUCCESS";
export const FETCH_ABOUT_US_CONTENT_FAILED = "FETCH_ABOUT_US_CONTENT_FAILED";

export const FETCH_CANDIDATES_CONTENT = "FETCH_CANDIDATES_CONTENT";
export const FETCH_CANDIDATES_CONTENT_SUCCESS =
  "FETCH_CANDIDATES_CONTENT_SUCCESS";
export const FETCH_CANDIDATES_CONTENT_FAILED =
  "FETCH_CANDIDATES_CONTENT_FAILED";

export const FETCH_CANDIDATE_DETAIL = "FETCH_CANDIDATE_DETAIL";
export const FETCH_CANDIDATE_DETAIL_SUCCESS = "FETCH_CANDIDATE_DETAIL_SUCCESS";
export const FETCH_CANDIDATE_DETAIL_FAILED = "FETCH_CANDIDATE_DETAIL_FAILED";

export const FETCH_JOB_DETAILS = "FETCH_JOB_DETAILS";
export const FETCH_JOB_DETAILS_SUCCESS = "FETCH_JOB_DETAILS_SUCCESS";
export const FETCH_JOB_DETAILS_FAILED = "FETCH_JOB_DETAILS_FAILED";

export const FETCH_EMPLOYER_JOBS = "FETCH_EMPLOYER_JOBS";
export const FETCH_EMPLOYER_JOBS_SUCCESS = "FETCH_EMPLOYER_JOBS_SUCCESS";
export const FETCH_EMPLOYER_JOBS_FAILED = "FETCH_EMPLOYER_JOBS_FAILED";

export const DELETE_JOB = "DELETE_JOB";
export const DELETE_JOB_SUCCESS = "DELETE_JOB_SUCCESS";
export const DELETE_JOB_FAILED = "DELETE_JOB_FAILED";

export const UPDATE_JOB = "UPDATE_JOB";
export const UPDATE_JOB_SUCCESS = "UPDATE_JOB_SUCCESS";
export const UPDATE_JOB_FAILED = "UPDATE_JOB_FAILED";

export const APPLY_ON_JOB = "APPLY_ON_JOB";
export const APPLY_ON_JOB_SUCCESS = "APPLY_ON_JOB_SUCCESS";
export const APPLY_ON_JOB_FAILED = "APPLY_ON_JOB_FAILED";

export const FETCH_CANDIDATE_APPLICATIONS = "FETCH_CANDIDATE_APPLICATIONS";
export const FETCH_CANDIDATE_APPLICATIONS_SUCCESS =
  "FETCH_CANDIDATE_APPLICATIONS_SUCCESS";
export const FETCH_CANDIDATE_APPLICATIONS_FAILED =
  "FETCH_CANDIDATE_APPLICATIONS_FAILED";

export const FETCH_JOB_APPLICANTS = "FETCH_JOB_APPLICANTS";
export const FETCH_JOB_APPLICANTS_SUCCESS = "FETCH_JOB_APPLICANTS_SUCCESS";
export const FETCH_JOB_APPLICANTS_FAILED = "FETCH_JOB_APPLICANTS_FAILED";

export const FETCH_PRICING_CONTENT = "FETCH_PRICING_CONTENT";
export const FETCH_PRICING_CONTENT_SUCCESS = "FETCH_PRICING_CONTENT_SUCCESS";
export const FETCH_PRICING_CONTENT_FAILED = "FETCH_PRICING_CONTENT_FAILED";

export const FETCH_TRAINING_CONTENT = "FETCH_TRAINING_CONTENT";
export const FETCH_TRAINING_CONTENT_SUCCESS = "FETCH_TRAINING_CONTENT_SUCCESS";
export const FETCH_TRAINING_CONTENT_FAILED = "FETCH_TRAINING_CONTENT_FAILED";

export const FETCH_CONTACT_US = "FETCH_CONTACT_US";
export const FETCH_CONTACT_US_SUCCESS = "FETCH_CONTACT_US_SUCCESS";
export const FETCH_CONTACT_US_FAILED = "FETCH_CONTACT_US_FAILED";
export const SAVE_CONTACT_MESSAGE = "SAVE_CONTACT_MESSAGE";
export const SAVE_CONTACT_MESSAGE_SUCCESS = "SAVE_CONTACT_MESSAGE_SUCCESS";
export const SAVE_CONTACT_MESSAGE_FAILED = "SAVE_CONTACT_MESSAGE_FAILED";

export const JOB_PUSH = "JOB_PUSH";
export const JOB_PUSH_SUCCESS = "JOB_PUSH_SUCCESS";
export const JOB_PUSH_FAILED = "JOB_PUSH_FAILED";

export const FETCH_SIGN_UP_CANDIDATE_CONTENT =
  "FETCH_SIGN_UP_CANDIDATE_CONTENT";
export const FETCH_SIGN_UP_CANDIDATE_CONTENT_SUCCESS =
  "FETCH_SIGN_UP_CANDIDATE_CONTENT_SUCCESS";
export const FETCH_SIGN_UP_CANDIDATE_CONTENT_FAILED =
  "FETCH_SIGN_UP_CANDIDATE_CONTENT_FAILED";
export const CREATE_CANDIDATE_ACCOUNT_MESSAGE =
  "CREATE_CANDIDATE_ACCOUNT_MESSAGE";
export const CREATE_CANDIDATE_ACCOUNT_MESSAGE_SUCCESS =
  "CREATE_CANDIDATE_ACCOUNT_MESSAGE_SUCCESS";
export const CREATE_CANDIDATE_ACCOUNT_MESSAGE_FAILED =
  "CREATE_CANDIDATE_ACCOUNT_MESSAGE_FAILED";
export const BACK_TO_SIGNUP = "BACK_TO_SIGNUP";
export const VERIFY_EMAIL = "VERIFY_EMAIL";
export const VERIFY_EMAIL_SUCCESS = "VERIFY_EMAIL_SUCCESS";
export const VERIFY_EMAIL_FAILED = "VERIFY_EMAIL_FAILED";

export const FETCH_SIGN_UP_EMPLOYER_CONTENT = "FETCH_SIGN_UP_EMPLOYER_CONTENT";
export const FETCH_SIGN_UP_EMPLOYER_CONTENT_SUCCESS =
  "FETCH_SIGN_UP_EMPLOYER_CONTENT_SUCCESS";
export const FETCH_SIGN_UP_EMPLOYER_CONTENT_FAILED =
  "FETCH_SIGN_UP_EMPLOYER_CONTENT_FAILED";
export const CREATE_EMPLOYER_ACCOUNT_MESSAGE =
  "CREATE_EMPLOYER_ACCOUNT_MESSAGE";
export const CREATE_EMPLOYER_ACCOUNT_MESSAGE_SUCCESS =
  "CREATE_EMPLOYER_ACCOUNT_MESSAGE_SUCCESS";
export const CREATE_EMPLOYER_ACCOUNT_MESSAGE_FAILED =
  "CREATE_EMPLOYER_ACCOUNT_MESSAGE_FAILED";

export const FETCH_SIGN_UP_CONTENT = "FETCH_SIGN_UP_CONTENT";
export const FETCH_SIGN_UP_CONTENT_SUCCESS = "FETCH_SIGN_UP_CONTENT_SUCCESS";
export const FETCH_SIGN_UP_CONTENT_FAILED = "FETCH_SIGN_UP_CONTENT_FAILED";
export const CREATE_ACCOUNT_MESSAGE = "CREATE_ACCOUNT_MESSAGE";
export const CREATE_ACCOUNT_MESSAGE_SUCCESS = "CREATE_ACCOUNT_MESSAGE_SUCCESS";
export const CREATE_ACCOUNT_MESSAGE_FAILED = "CREATE_ACCOUNT_MESSAGE_FAILED";

export const FETCH_FORGOT_PASSWORD_CONTENT = "FETCH_FORGOT_PASSWORD_CONTENT";
export const FETCH_FORGOT_PASSWORD_CONTENT_SUCCESS =
  "FETCH_FORGOT_PASSWORD_CONTENT_SUCCESS";
export const FETCH_FORGOT_PASSWORD_CONTENT_FAILED =
  "FETCH_FORGOT_PASSWORD_CONTENT_FAILED";
export const FORGOT_PASSWORD_LINK = "FORGOT_PASSWORD_LINK";
export const FORGOT_PASSWORD_LINK_SUCCESS = "FORGOT_PASSWORD_LINK_SUCCESS";
export const FORGOT_PASSWORD_LINK_FAILED = "FORGOT_PASSWORD_LINK_FAILED";

export const FETCH_RESET_PASSWORD_CONTENT = "FETCH_RESET_PASSWORD_CONTENT";
export const FETCH_RESET_PASSWORD_CONTENT_SUCCESS =
  "FETCH_RESET_PASSWORD_CONTENT_SUCCESS";
export const FETCH_RESET_PASSWORD_CONTENT_FAILED =
  "FETCH_RESET_PASSWORD_CONTENT_FAILED";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILED = "RESET_PASSWORD_FAILED";

export const FETCH_SIGN_IN_CONTENT = "FETCH_SIGN_IN_CONTENT";
export const FETCH_SIGN_IN_CONTENT_SUCCESS = "FETCH_SIGN_IN_CONTENT_SUCCESS";
export const FETCH_SIGN_IN_CONTENT_FAILED = "FETCH_SIGN_IN_CONTENT_FAILED";
export const SIGN_IN_ACCOUNT_MESSAGE = "SIGN_IN_ACCOUNT_MESSAGE";
export const SIGN_IN_ACCOUNT_MESSAGE_SUCCESS =
  "SIGN_IN_ACCOUNT_MESSAGE_SUCCESS";
export const SIGN_IN_ACCOUNT_MESSAGE_FAILED = "SIGN_IN_ACCOUNT_MESSAGE_FAILED";

export const FETCH_VIDEO_INTERVIEW_CONTENT = "FETCH_VIDEO_INTERVIEW_CONTENT";
export const FETCH_VIDEO_INTERVIEW_CONTENT_SUCCESS =
  "FETCH_VIDEO_INTERVIEW_CONTENT_SUCCESS";
export const FETCH_VIDEO_INTERVIEW_CONTENT_FAILED =
  "FETCH_VIDEO_INTERVIEW_CONTENT_FAILED";
export const FETCH_CATEGORY_QUESTIONS = "FETCH_CATEGORY_QUESTIONS";
export const FETCH_CATEGORY_QUESTIONS_SUCCESS =
  "FETCH_CATEGORY_QUESTIONS_SUCCESS";
export const FETCH_CATEGORY_QUESTIONS_FAILED =
  "FETCH_CATEGORY_QUESTIONS_FAILED";
export const SAVE_INTERVIEW_VIDEO = "SAVE_INTERVIEW_VIDEO";
export const SAVE_INTERVIEW_VIDEO_SUCCESS = "SAVE_INTERVIEW_VIDEO_SUCCESS";
export const SAVE_INTERVIEW_VIDEO_FAILED = "SAVE_INTERVIEW_VIDEO_FAILED";

export const FETCH_DASHBOARD = "FETCH_DASHBOARD";
export const FETCH_DASHBOARD_SUCCESS = "FETCH_DASHBOARD_SUCCESS";
export const FETCH_DASHBOARD_FAILED = "FETCH_DASHBOARD_FAILED";
export const SAVE_JOB_STAT = "SAVE_JOB_STAT";
export const SAVE_JOB_STAT_SUCCESS = "SAVE_JOB_STAT_SUCCESS";
export const SAVE_JOB_STAT_FAILED = "SAVE_JOB_STAT_FAILED";
export const FETCH_JOB_STAT = "FETCH_JOB_STAT";

export const SAVE_LIKE_JOB = "SAVE_LIKE_JOB";
export const SAVE_LIKE_JOB_SUCCESS = "SAVE_LIKE_JOB_SUCCESS";
export const SAVE_LIKE_JOB_FAILED = "SAVE_LIKE_JOB_FAILED";

export const FETCH_SAVED_JOBS = "FETCH_SAVED_JOBS";
export const FETCH_SAVED_JOBS_SUCCESS = "FETCH_SAVED_JOBS_SUCCESS";
export const FETCH_SAVED_JOBS_FAILED = "FETCH_SAVED_JOBS_FAILED";

export const DELETE_SAVED_JOB = "DELETE_SAVED_JOB";
export const DELETE_SAVED_JOB_SUCCESS = "DELETE_SAVED_JOB_SUCCESS";
export const DELETE_SAVED_JOB_FAILED = "DELETE_SAVED_JOB_FAILED";

export const FETCH_PROFILE_SETTINGS = "FETCH_PROFILE_SETTINGS";
export const FETCH_PROFILE_SETTINGS_SUCCESS = "FETCH_PROFILE_SETTINGS_SUCCESS";
export const FETCH_PROFILE_SETTINGS_FAILED = "FETCH_PROFILE_SETTINGS_FAILED";
export const SAVE_PROFILE_SETTINGS = "SAVE_PROFILE_SETTINGS";
export const SAVE_PROFILE_SETTINGS_SUCCESS = "SAVE_PROFILE_SETTINGS_SUCCESS";
export const SAVE_PROFILE_SETTINGS_FAILED = "SAVE_PROFILE_SETTINGS_FAILED";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILED = "CHANGE_PASSWORD_FAILED";

export const FETCH_EVENTS_CONTENT = "FETCH_EVENTS_CONTENT";
export const FETCH_EVENTS_CONTENT_SUCCESS = "FETCH_EVENTS_CONTENT_SUCCESS";
export const FETCH_EVENTS_CONTENT_FAILED = "FETCH_EVENTS_CONTENT_FAILED";
export const FETCH_EVENT_DETAIL_CONTENT = "FETCH_EVENT_DETAIL_CONTENT";
export const FETCH_EVENT_DETAIL_CONTENT_SUCCESS =
  "FETCH_EVENT_DETAIL_CONTENT_SUCCESS";
export const FETCH_EVENT_DETAIL_CONTENT_FAILED =
  "FETCH_EVENT_DETAIL_CONTENT_FAILED";
export const FETCH_EVENTS_SEARCH = "FETCH_EVENTS_SEARCH";
export const FETCH_EVENTS_SEARCH_SUCCESS = "FETCH_EVENTS_SEARCH_SUCCESS";
export const FETCH_EVENTS_SEARCH_FAILED = "FETCH_EVENTS_SEARCH_FAILED";

export const FETCH_BLOGS_CONTENT = "FETCH_BLOGS_CONTENT";
export const FETCH_BLOGS_CONTENT_SUCCESS = "FETCH_BLOGS_CONTENT_SUCCESS";
export const FETCH_BLOGS_CONTENT_FAILED = "FETCH_BLOGS_CONTENT_FAILED";
export const FETCH_BLOGS_SEARCH = "FETCH_BLOGS_SEARCH";
export const FETCH_BLOGS_SEARCH_SUCCESS = "FETCH_BLOGS_SEARCH_SUCCESS";
export const FETCH_BLOGS_SEARCH_FAILED = "FETCH_BLOGS_SEARCH_FAILED";

export const FETCH_BLOG_DETAIL_CONTENT = "FETCH_BLOG_DETAIL_CONTENT";
export const FETCH_BLOG_DETAIL_CONTENT_SUCCESS =
  "FETCH_BLOG_DETAIL_CONTENT_SUCCESS";
export const FETCH_BLOG_DETAIL_CONTENT_FAILED =
  "FETCH_BLOG_DETAIL_CONTENT_FAILED";

export const FETCH_JOB_PROFILE_DETAIL_CONTENT =
  "FETCH_JOB_PROFILE_DETAIL_CONTENT";
export const FETCH_JOB_PROFILE_DETAIL_CONTENT_SUCCESS =
  "FETCH_JOB_PROFILE_DETAIL_CONTENT_SUCCESS";
export const FETCH_JOB_PROFILE_DETAIL_CONTENT_FAILED =
  "FETCH_JOB_PROFILE_DETAIL_CONTENT_FAILED";

export const FETCH_UK_CORPORATE_CONTENT = "FETCH_UK_CORPORATE_CONTENT";
export const FETCH_UK_CORPORATE_CONTENT_SUCCESS =
  "FETCH_UK_CORPORATE_CONTENT_SUCCESS";
export const FETCH_UK_CORPORATE_CONTENT_FAILED =
  "FETCH_UK_CORPORATE_CONTENT_FAILED";

export const FETCH_TESTIMONIALS_CONTENT = "FETCH_TESTIMONIALS_CONTENT";
export const FETCH_TESTIMONIALS_CONTENT_SUCCESS =
  "FETCH_TESTIMONIALS_CONTENT_SUCCESS";
export const FETCH_TESTIMONIALS_CONTENT_FAILED =
  "FETCH_TESTIMONIALS_CONTENT_FAILED";

export const FETCH_RECR_PROCESS_CONTENT = "FETCH_RECR_PROCESS_CONTENT";
export const FETCH_RECR_PROCESS_CONTENT_SUCCESS =
  "FETCH_RECR_PROCESS_CONTENT_SUCCESS";
export const FETCH_RECR_PROCESS_CONTENT_FAILED =
  "FETCH_RECR_PROCESS_CONTENT_FAILED";

export const FETCH_CV_COVER_LETTER_CONTENT = "FETCH_CV_COVER_LETTER_CONTENT";
export const FETCH_CV_COVER_LETTER_CONTENT_SUCCESS =
  "FETCH_CV_COVER_LETTER_CONTENT_SUCCESS";
export const FETCH_CV_COVER_LETTER_CONTENT_FAILED =
  "FETCH_CV_COVER_LETTER_CONTENT_FAILED";

export const FETCH_CV_GUIDANCE_CONTENT = "FETCH_CV_GUIDANCE_CONTENT";
export const FETCH_CV_GUIDANCE_CONTENT_SUCCESS =
  "FETCH_CV_GUIDANCE_CONTENT_SUCCESS";
export const FETCH_CV_GUIDANCE_CONTENT_FAILED =
  "FETCH_CV_GUIDANCE_CONTENT_FAILED";

export const FETCH_COVER_LETTER_GUIDANCE_CONTENT =
  "FETCH_COVER_LETTER_GUIDANCE_CONTENT";
export const FETCH_COVER_LETTER_GUIDANCE_CONTENT_SUCCESS =
  "FETCH_COVER_LETTER_GUIDANCE_CONTENT_SUCCESS";
export const FETCH_COVER_LETTER_GUIDANCE_CONTENT_FAILED =
  "FETCH_COVER_LETTER_GUIDANCE_CONTENT_FAILED";

export const FETCH_CV_BUILDER_CONTENT = "FETCH_CV_BUILDER_CONTENT";
export const FETCH_CV_BUILDER_CONTENT_SUCCESS =
  "FETCH_CV_BUILDER_CONTENT_SUCCESS";
export const FETCH_CV_BUILDER_CONTENT_FAILED =
  "FETCH_CV_BUILDER_CONTENT_FAILED";

export const FETCH_CV_DETAILS = "FETCH_CV_DETAIL";
export const FETCH_CV_DETAILS_SUCCESS = "FETCH_CV_DETAIL_SUCCESS";
export const FETCH_CV_DETAILS_FAILED = "FETCH_CV_DETAIL_FAILED";
export const SAVE_CV = "SAVE_CV";
export const SAVE_CV_SUCCESS = "SAVE_CV_SUCCESS";
export const SAVE_CV_FAILED = "SAVE_CV_FAILED";

export const FETCH_COVER_LETTER_BUILDER_CONTENT =
  "FETCH_COVER_LETTER_BUILDER_CONTENT";
export const FETCH_COVER_LETTER_BUILDER_CONTENT_SUCCESS =
  "FETCH_COVER_LETTER_BUILDER_CONTENT_SUCCESS";
export const FETCH_COVER_LETTER_BUILDER_CONTENT_FAILED =
  "FETCH_COVER_LETTER_BUILDER_CONTENT_FAILED";
export const SAVE_COVER = "SAVE_COVER";
export const SAVE_COVER_SUCCESS = "SAVE_COVER_SUCCESS";
export const SAVE_COVER_FAILED = "SAVE_COVER_FAILED";

export const FETCH_ASSESSMENT_CENTER_CONTENT =
  "FETCH_ASSESSMENT_CENTER_CONTENT";
export const FETCH_ASSESSMENT_CENTER_CONTENT_SUCCESS =
  "FETCH_ASSESSMENT_CENTER_CONTENT_SUCCESS";
export const FETCH_ASSESSMENT_CENTER_CONTENT_FAILED =
  "FETCH_ASSESSMENT_CENTER_CONTENT_FAILED";

export const FETCH_INTERVIEW_CONTENT = "FETCH_INTERVIEW_CONTENT";
export const FETCH_INTERVIEW_CONTENT_SUCCESS =
  "FETCH_INTERVIEW_CONTENT_SUCCESS";
export const FETCH_INTERVIEW_CONTENT_FAILED = "FETCH_INTERVIEW_CONTENT_FAILED";

export const FETCH_ONLINE_TEST_CONTENT = "FETCH_ONLINE_TEST_CONTENT";
export const FETCH_ONLINE_TEST_CONTENT_SUCCESS =
  "FETCH_ONLINE_TEST_CONTENT_SUCCESS";
export const FETCH_ONLINE_TEST_CONTENT_FAILED =
  "FETCH_ONLINE_TEST_CONTENT_FAILED";

export const FETCH_ONLINE_TEST_CATEGORIES_CONTENT =
  "FETCH_ONLINE_TEST_CATEGORIES_CONTENT";
export const FETCH_ONLINE_TEST_CATEGORIES_CONTENT_SUCCESS =
  "FETCH_ONLINE_TEST_CATEGORIES_CONTENT_SUCCESS";
export const FETCH_ONLINE_TEST_CATEGORIES_CONTENT_FAILED =
  "FETCH_ONLINE_TEST_CATEGORIES_CONTENT_FAILED";

export const FETCH_TEST_CATEGORY_DETAIL_CONTENT =
  "FETCH_TEST_CATEGORY_DETAIL_CONTENT";
export const FETCH_TEST_CATEGORY_DETAIL_CONTENT_SUCCESS =
  "FETCH_TEST_CATEGORY_DETAIL_CONTENT_SUCCESS";
export const FETCH_TEST_CATEGORY_DETAIL_CONTENT_FAILED =
  "FETCH_TEST_CATEGORY_DETAIL_CONTENT_FAILED";

export const FETCH_CAREER_OPTIONS_CONTENT = "FETCH_CAREER_OPTIONS_CONTENT";
export const FETCH_CAREER_OPTIONS_CONTENT_SUCCESS =
  "FETCH_CAREER_OPTIONS_CONTENT_SUCCESS";
export const FETCH_CAREER_OPTIONS_CONTENT_FAILED =
  "FETCH_CAREER_OPTIONS_CONTENT_FAILED";

export const FETCH_UNI_VS_EMP_CONTENT = "FETCH_UNI_VS_EMP_CONTENT";
export const FETCH_UNI_VS_EMP_CONTENT_SUCCESS =
  "FETCH_UNI_VS_EMP_CONTENT_SUCCESS";
export const FETCH_UNI_VS_EMP_CONTENT_FAILED =
  "FETCH_UNI_VS_EMP_CONTENT_FAILED";

export const FETCH_INTERVIEW_CATEGORY_INSTRUCTION =
  "FETCH_INTERVIEW_CATEGORY_INSTRUCTION";
export const FETCH_INTERVIEW_CATEGORY_INSTRUCTION_SUCCESS =
  "FETCH_INTERVIEW_CATEGORY_INSTRUCTION_SUCCESS";
export const FETCH_INTERVIEW_CATEGORY_INSTRUCTION_FAILED =
  "FETCH_INTERVIEW_CATEGORY_INSTRUCTION_FAILED";

export const SAVE_PAYMENT_METHOD = "SAVE_PAYMENT_METHOD";
export const SAVE_PAYMENT_METHOD_SUCCESS = "SAVE_PAYMENT_METHOD_SUCCESS";
export const SAVE_PAYMENT_METHOD_FAILED = "SAVE_PAYMENT_METHOD_FAILED";

export const FETCH_PAYMENT_METHODS = "FETCH_PAYMENT_METHODS";
export const FETCH_PAYMENT_METHODS_SUCCESS = "FETCH_PAYMENT_METHODS_SUCCESS";
export const FETCH_PAYMENT_METHODS_FAILED = "FETCH_PAYMENT_METHODS_FAILED";

export const DELETE_PAYMENT_METHOD = "DELETE_PAYMENT_METHOD";
export const DELETE_PAYMENT_METHOD_SUCCESS = "DELETE_PAYMENT_METHOD_SUCCESS";
export const DELETE_PAYMENT_METHOD_FAILED = "DELETE_PAYMENT_METHOD_FAILED";

export const FETCH_PAYMENT_METHOD_DETAILS = "FETCH_PAYMENT_METHOD_DETAILS";
export const FETCH_PAYMENT_METHOD_DETAILS_SUCCESS =
  "FETCH_PAYMENT_METHOD_DETAILS_SUCCESS";
export const FETCH_PAYMENT_METHOD_DETAILS_FAILED =
  "FETCH_PAYMENT_METHOD_DETAILS_FAILED";

export const UPDATE_PAYMENT_METHOD = "UPDATE_PAYMENT_METHOD";
export const UPDATE_PAYMENT_METHOD_SUCCESS = "UPDATE_PAYMENT_METHOD_SUCCESS";
export const UPDATE_PAYMENT_METHOD_FAILED = "UPDATE_PAYMENT_METHOD_FAILED";

export const FETCH_ALL_MEMBERS = "FETCH_ALL_MEMBERS";
export const FETCH_ALL_MEMBERS_SUCCESS = "FETCH_ALL_MEMBERS_SUCCESS";
export const FETCH_ALL_MEMBERS_FAILED = "FETCH_ALL_MEMBERS_FAILED";

export const DOWNLOAD_CV = "DOWNLOAD_CV";
export const DOWNLOAD_CV_SUCCESS = "DOWNLOAD_CV_SUCCESS";
export const DOWNLOAD_CV_FAILED = "DOWNLOAD_CV_FAILED";

export const FETCH_ALL_CANDIDATES = "FETCH_ALL_CANDIDATES";
export const FETCH_ALL_CANDIDATES_SUCCESS = "FETCH_ALL_CANDIDATES_SUCCESS";
export const FETCH_ALL_CANDIDATES_FAILED = "FETCH_ALL_CANDIDATES_FAILED";

export const FETCH_NOTIFICATIONS = "FETCH_NOTIFICATIONS";
export const FETCH_NOTIFICATIONS_SUCCESS = "FETCH_NOTIFICATIONS_SUCCESS";
export const FETCH_NOTIFICATIONS_FAILED = "FETCH_NOTIFICATIONS_FAILED";

export const DELETE_NOTIFICATIONS = "DELETE_NOTIFICATIONS";
export const DELETE_NOTIFICATIONS_SUCCESS = "DELETE_NOTIFICATIONS_SUCCESS";
export const DELETE_NOTIFICATIONS_FAILED = "DELETE_NOTIFICATIONS_FAILED";

export const FETCH_EMPLOYER_DETAILS = "FETCH_EMPLOYER_DETAILS";
export const FETCH_EMPLOYER_DETAILS_SUCCESS = "FETCH_EMPLOYER_DETAILS_SUCCESS";
export const FETCH_EMPLOYER_DETAILS_FAILED = "FETCH_EMPLOYER_DETAILS_FAILED";