import React from "react";
import { Navigate, useLocation, Outlet } from "react-router-dom";

const CandidateRoutes = () => {
  let location = useLocation();
  const memType = localStorage.getItem('memType');

  return memType == 'candidate' ? (
    <Outlet />
  ) : (
    <Navigate to={
      localStorage.getItem("site_lang") === "eng"
        ? "/signin"
        : "/connexion"
    } replace state={{ from: location }} />
  );
};

export default CandidateRoutes;
